import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import Home from './pages/Home';  
import About from './pages/About';
import Gallery from './pages/Gallery';
import Products from './pages/Products';
import Brands from './pages/Brands';
import Contact from './pages/Contact';
import NotFound from './pages/404';

const App = () => {
  return (
    <Router>
     <MainLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/products" element={<Products />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      </MainLayout>
    </Router>
  );
};

export default App;

import React from 'react';
import WhatWeDo from './../components/WhatWeDo';
import WhyChoiseUs from './../components/WhyChoiseUs';
import WhoWeAre from './../components/WhoWeAre';

const About = () => {
  return (
    <>

      <div id="inner_banner" class="section inner_banner_section">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="full">
                     <div class="title-holder">
                        <div class="title-holder-cell text-left">
                           <h1 class="page-title">About Us</h1>
                           <ol class="breadcrumb">
                              <li><a href="home1.html">Home</a></li>
                              <li class="active">About Us</li>
                           </ol>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <WhatWeDo/>
      <WhyChoiseUs/>
      <WhoWeAre/>
    </>
  );
};

export default About;

import React, { useState } from 'react';

function Gallery() {
  const [filter, setFilter] = useState('all'); // Default filter is 'all'

  const images = [
        { "src": "images/products/gallery01.jpg", "category": "plywood" },
        { "src": "images/products/gallery02.jpg", "category": "plywood" },
        { "src": "images/products/gallery03.jpg", "category": "plywood" },
        { "src": "images/products/gallery04.jpg", "category": "plywood" },
        { "src": "images/products/gallery05.jpg", "category": "hardwares" },
        { "src": "images/products/gallery06.jpg", "category": "hardwares" },
        { "src": "images/products/gallery07.jpg", "category": "others" },
        { "src": "images/products/gallery08.jpg", "category": "others" },
        { "src": "images/products/gallery09.jpg", "category": "others" },
        { "src": "images/products/gallery10.jpg", "category": "hardwares" },
        { "src": "images/products/gallery11.jpg", "category": "hardwares" },
        { "src": "images/products/gallery12.jpg", "category": "hardwares" },
        { "src": "images/products/gallery13.jpg", "category": "hardwares" },
        { "src": "images/products/gallery14.jpg", "category": "hardwares" },
        { "src": "images/products/gallery15.jpg", "category": "hardwares" },
        { "src": "images/products/gallery16.jpg", "category": "others" },
        { "src": "images/products/gallery17.jpg", "category": "hardwares" },
        { "src": "images/products/gallery18.jpg", "category": "hardwares" },
        { "src": "images/products/gallery19.jpg", "category": "others" },
        { "src": "images/products/gallery20.jpg", "category": "plywood" },
        { "src": "images/products/gallery21.jpg", "category": "plywood" },
        { "src": "images/products/gallery22.jpg", "category": "plywood" },
        { "src": "images/products/gallery23.jpg", "category": "decoration" },
        { "src": "images/products/gallery24.jpg", "category": "decoration" },
        { "src": "images/products/gallery25.jpg", "category": "plywood" },
        { "src": "images/products/gallery26.jpg", "category": "plywood" },
        { "src": "images/products/gallery27.jpg", "category": "plywood" },
        { "src": "images/products/gallery28.jpg", "category": "hardwares" },
        { "src": "images/products/gallery29.jpg", "category": "plywood" },
        { "src": "images/products/gallery31.jpg", "category": "plywood" }  
  ];

  const handleFilterClick = (category) => {
    setFilter(category);
  };

  return (
    <div id="gallery" className="section padding_layout_1 portfolio_section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="full">
              <div className="main_heading text_align_center">
                <h2><span>Gallery</span></h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="full">
              <div className="gallery_tab">
                <div className="center">
                  <div className="tab_buttons">
                    <button
                      className="cuts_bt filter-button"
                      onClick={() => handleFilterClick('all')}
                    >
                      All
                    </button>
                    <button
                      className="cuts_bt filter-button"
                      onClick={() => handleFilterClick('plywood')}
                    >
                      Plywoods
                    </button>
                    <button
                      className="cuts_bt filter-button"
                      onClick={() => handleFilterClick('decoration')}
                    >
                      Decoration
                    </button>
                    <button
                      className="cuts_bt filter-button"
                      onClick={() => handleFilterClick('hardwares')}
                    >
                      Hardwares
                    </button>
                    <button
                      className="cuts_bt filter-button"
                      onClick={() => handleFilterClick('others')}
                    >
                      Others
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {images
            .filter(image => filter === 'all' || image.category === filter)
            .map((image, index) => (
              <div key={index} className={`gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter ${image.category}`}>
                <img src={image.src} alt={`gallery-${index}`} className="img-responsive" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Gallery;

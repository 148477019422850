import React from 'react';

function Products() {
  const products =[
        { "id": 1, "name": "Sagwan Timber", "image": "images/products/pro_01.jpg" },
        { "id": 2, "name": "Plywood", "image": "images/products/pro_02.jpg" },
        { "id": 3, "name": "Board", "image": "images/products/pro_03.jpg" },
        { "id": 4, "name": "Particle Board", "image": "images/products/pro_04.jpg" },
        { "id": 5, "name": "Laminate Board", "image": "images/products/pro_05.jpg" },
        { "id": 6, "name": "Vaneer", "image": "images/products/pro_06.jpg" },
        { "id": 7, "name": "Vaneer Door Skin", "image": "images/products/pro_07.jpg" },
        { "id": 8, "name": "Door", "image": "images/products/pro_08.jpg" },
        { "id": 9, "name": "Flush Door", "image": "images/products/pro_09.jpg" },
        { "id": 10, "name": "Molded Door", "image": "images/products/pro_10.jpg" },
        { "id": 11, "name": "Plastic Door", "image": "images/products/pro_11.jpg" },
        { "id": 12, "name": "Door skin", "image": "images/products/pro_12.jpg" },
        { "id": 13, "name": "Modular Furniture", "image": "images/products/pro_13.jpg" },
        { "id": 14, "name": "Wallpaper Furniture", "image": "images/products/pro_14.jpg" },
        { "id": 15, "name": "Sunmica", "image": "images/products/pro_15.jpg" },
        { "id": 16, "name": "Laminate Sheet", "image": "images/products/pro_16.jpg" },
        { "id": 17, "name": "Hardware", "image": "images/products/pro_17.jpg" },
        { "id": 18, "name": "Fancy Hardware", "image": "images/products/pro_18.jpg" },
        { "id": 19, "name": "Kitchen Chimney", "image": "images/products/pro_19.jpg" },
        { "id": 20, "name": "Kitchen Accessories", "image": "images/products/pro_20.jpg" },
        { "id": 21, "name": "Kitchen Chimney", "image": "images/products/pro_21.jpg" },
        { "id": 22, "name": "Kitchen Basket", "image": "images/products/pro_22.jpg" },
        { "id": 23, "name": "Hardware", "image": "images/products/pro_23.jpg" },
        { "id": 24, "name": "Hardware", "image": "images/products/pro_24.jpg" },
        { "id": 25, "name": "Decoration Items", "image": "images/products/pro_25.jpg" },
        { "id": 26, "name": "Water Geyser", "image": "images/products/pro_26.jpg" },
        { "id": 27, "name": "Stoves", "image": "images/products/pro_27.jpg" },
        { "id": 28, "name": "MDF", "image": "images/products/pro_28.jpg" },
        { "id": 29, "name": "Kitchen Chimney", "image": "images/products/pro_29.jpg" },
        { "id": 30, "name": "Wallpaper", "image": "images/products/pro_30.jpg" },
        { "id": 31, "name": "Kitchen Basket", "image": "images/products/pro_31.jpg" }
  ]
  

  return (
    <div id="products" className="section padding_layout_1">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="full">
              <div className="main_heading text_align_center heading_with_subtitle">
                <h2><span>Our Products</span></h2>
                <p className="large">We package the products with best services to make you a<br />happy customer.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {products.map((product) => (
            <div key={product.id} className="col-lg-2 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
              <div className="product_list">
                <div className="product_img">
                  <img className="img-responsive" src={product.image} alt={product.name} />
                </div>
                <div className="product_detail_btm">
                  <div className="center">
                    <h4><a href="shop_detail.html">{product.name}</a></h4>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Products;

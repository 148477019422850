import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div classNameName="not-found">
  
      <div id="inner_banner" className="section inner_banner_section">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="full">
                     <div className="title-holder">
                        <div className="title-holder-cell text-left">
                           <h1 className="page-title">404 Error</h1>
                           <ol className="breadcrumb">
                              <li><a href="index.html">Home</a></li>
                              <li>Pages</li>
                              <li className="active">404 Error</li>
                           </ol>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div className="section padding_layout_1">
         <div className="container">
            <div className="row">
               <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="center margin_bottom_30_all"> <img className="margin_bottom_30_all" src="images/layout_img/404_error_img.png" alt="#"/> </div>
                  <div className="heading text_align_center">
                     <h2>OOOPS, THIS PAGE COULD NOT BE FOUND!</h2>
                  </div>
                  <div className="center">
                  <Link to="/" classNameName="btn sqaure_bt light_theme_bt">Back Home </Link> </div>
               </div>
            </div>
         </div>
      </div>

    </div>
  );
};

export default NotFound;

import React from 'react'

function Review() {
  return (
    <div>
         <div className="section padding_layout_1 testmonial_section white_fonts">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="full">
                     <div className="main_heading text_align_left">
                        <h2 style={{ textTransform: 'none' }}>What Clients Say?</h2>
                        <p className="large">Here are testimonials from clients..</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-sm-7">
                  <div className="full">
                     <div id="testimonial_slider" className="carousel slide" data-ride="carousel">
                        <ul className="carousel-indicators">
                           <li data-target="#testimonial_slider" data-slide-to="0" className="active"></li>
                           <li data-target="#testimonial_slider" data-slide-to="1"></li>
                           <li data-target="#testimonial_slider" data-slide-to="2"></li>
                        </ul>
   
                        <div className="carousel-inner">
                           <div className="carousel-item active">
                              <div className="testimonial-container">
                                 <div className="testimonial-content"> The plywood and hardware we purchased were of excellent quality. The team was very supportive and made the entire process smooth. Highly satisfied with the service!
                                 </div>
                                 <div className="testimonial-photo"> <img src="images/layout_img/user-male.png" className="img-responsive" alt="#" width="150" height="150"/> </div>
                                 <div className="testimonial-meta">
                                    <h4> - Rajesh Sharma</h4>
                                    <span className="testimonial-position">Customer, Jaipur</span> 
                                 </div>
                              </div>
                           </div>
                           <div className="carousel-item">
                              <div className="testimonial-container">
                                 <div className="testimonial-content">I recently renovated my home, and the plywood and hardware from your store were just perfect. Great quality and affordable prices. Highly recommend your products!
                                 </div>
                                 <div className="testimonial-photo"> <img src="images/layout_img/user-female.png" className="img-responsive" alt="#" width="150" height="150"/> </div>
                                 <div className="testimonial-meta">
                                    <h4> - Kavita Verma</h4>
                                    <span className="testimonial-position">Homeowner, Ajmer</span> 
                                 </div>
                              </div>
                           </div>
                           <div className="carousel-item">
                              <div className="testimonial-container">
                                 <div className="testimonial-content"> Your plywood and hardware are reliable and long-lasting. We’ve been using them for our projects across Rajasthan, and the results are always fantastic. Great job!
                                 </div>
                                 <div className="testimonial-photo"> <img src="images/layout_img/user-male.png" className="img-responsive" alt="#" width="150" height="150"/> </div>
                                 <div className="testimonial-meta">
                                    <h4> - Arvind Jain</h4>
                                    <span className="testimonial-position">Contractor, Udaipur</span> 
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-sm-5">
                  <div className="full"> </div>
               </div>
            </div>
         </div>
      </div>
      
    </div>
  )
}

export default Review

// src/components/Header.js
import React from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';

const Header = () => {
  return (
    <header id="default_header" className="header_style_1">
      <div className="header_top">
        <Container>
          <div className="row">
            <div className="col-md-9 col-lg-9">
              <div className="topbar-left">
                <ul className="list-inline">
                  <li>
                    <span className="topbar-label"><i className="fa fa-home"></i></span>
                    <span className="topbar-hightlight">Near SBI Bank, Kanta Choraha, Kalwar Road, Jhotwara, Jaipur (Raj)</span>
                  </li>
                  <li>
                    <span className="topbar-label"><i className="fa fa-envelope-o"></i></span>
                    <span className="topbar-hightlight"><a href="mailto:deepakplywood@yahoo.com">deepakplywood@yahoo.com</a></span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 right_section_header_top">
              <div className="float-right">
                <div className="social_icon">
                  <ul className="list-inline">
                    <li><a className="fa fa-facebook" href="https://www.facebook.com/profile.php?id=61566204182461" title="Facebook" target="_blank" rel="noopener noreferrer"></a></li>
                    <li><a className="fa fa-twitter" href="https://x.com/deepakplywoods" title="Twitter" target="_blank" rel="noopener noreferrer"></a></li>
                    <li><a className="fa fa-instagram" href="https://www.instagram.com/deepakplywoodtimbersjaipur/" title="Instagram" target="_blank" rel="noopener noreferrer"></a></li>
                    <li><a className="fa fa-youtube" href="https://www.youtube.com/@Deepakplywoodjaipur " title="Instagram" target="_blank" rel="noopener noreferrer"></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="header_bottom">
        <Container>
          <Row className="align-items-center">
            <Col lg={4} md={12}>
              <div className="logo">
                <a href="/"><img src="images/logos/logo-deepak.png" alt="logo" /></a>
              </div>
            </Col>
            <Col lg={8} md={12}>
              <nav className="navbar navbar-expand-lg">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul  className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <a className="nav-link active" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/about">About Us</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/gallery">Gallery</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/products">Our Product</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/brands">Our Brand</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default Header;


import Slider from './../components/Slider';
import WhatWeDo from './../components/WhatWeDo';
import WhyChoiseUs from './../components/WhyChoiseUs';
import WhoWeAre from './../components/WhoWeAre';
import Review from '../components/Review';
import ScrollToTop from './../components/ScrollToTop'


function Home() {
  return (
    <>
      <Slider/>
      <div className="section padding_layout_1 section_information white_fonts">
         <div className="container">
            <div className="row">
               <div className="col-md-6">
                  <div className="full">
                     <h4>Building Trust, One Timber at a Time</h4>
                  </div>
               </div>
               <div className="col-md-6">
                  <div className="full">
                     <p>Deepak Plywood & Timbers is more than a name; it's a mark of trust and quality in the timber and plywood industry. With a skilled team and a commitment to affordable excellence, we offer products crafted for durability and performance. Our dedication to providing only the best ensures every customer walks away with materials they can rely on—and a project built to last. From expert guidance to award-winning service, we make your building journey seamless and successful.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <WhatWeDo/>
      <div className="section padding_layout_1 section_information white_fonts">
         <div className="container">
            <div className="row">
               <div className="col-md-6">
                  <div className="full">
                     <h4>Crafted for Quality, Trusted for Durability</h4>
                  </div>
               </div>
               <div className="col-md-6">
                  <div className="full">
                     <p>At Deepak Plywood & Timbers, we bring together premium quality materials, expert craftsmanship, and over a decade of experience to deliver products that stand the test of time. Whether you’re working on a small home renovation or a large-scale construction project, our team is here to support your vision with top-tier materials, affordable solutions, and a commitment to excellence. From our award-winning quality to our dedicated service, we’re more than a supplier; we’re your partner in building something extraordinary.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <WhyChoiseUs/>
      <div className="section padding_layout_1 section_information white_fonts">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="full">
                     <div id="counter">
                        <div className="counter_blog">
                           <div className="icon"><img src="images/layout_img/pr-compl.png" alt="#" /></div>
                           <div className="inform_count">
                              <div className="counter-value" data-count="6000">0+</div>
                              <h3>Project Complete</h3>
                           </div>
                        </div>
                        <div className="counter_blog">
                           <div className="icon"><img src="images/layout_img/happy_client.png" alt="#" /></div>
                           <div className="inform_count">
                              <div className="counter-value" data-count="10000">0+</div>
                              <h3>Happy Clients</h3>
                           </div>
                        </div>
                        <div className="counter_blog">
                           <div className="icon"><img src="images/layout_img/award.png" alt="#" /></div>
                           <div className="inform_count">
                              <div className="counter-value" data-count="250">0+</div>
                              <h3>Awards Won</h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <WhoWeAre/>
     
      <Review/>
      
      {/* <Product/>
      <Brand/> */}
       <ScrollToTop />
    </>
  );
}

export default Home;
